<template v-slot:no-data>
	<v-card dense>
		<v-row style="margin-top: 4px;">
			<v-col cols="12" lg="12" md="12">
				<v-tabs v-model="currentItem">

                    <v-tab href="#ChangeTunnel">Ingreso Tunel</v-tab>
                    <v-tab href="#ChangeCamara">Ingreso Camara</v-tab>
                    <v-tab href="#ChangeCaliforniano">Ingreso Californiano</v-tab>
                    <v-tab href="#ChangePlataforma">Ingreso Plataforma</v-tab>
                </v-tabs>

                <v-tabs-items v-model="currentItem">
                    <v-tab-item :value="'ChangeTunnel'">
                        <change-tunnel/>
                    </v-tab-item>
                    <v-tab-item :value="'ChangeCamara'">
                        <change-chamber/>
                    </v-tab-item>
                    <v-tab-item :value="'ChangeCaliforniano'">
                        <change-californian/>
                    </v-tab-item>
                    <v-tab-item :value="'ChangePlataforma'">
                        <change-environment/>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

    import ChangeTunnel from './FrzChangeTunnelQR.vue'
    import ChangeChamber from './FrzChangeChamberQR.vue'
	import ChangeCalifornian from './FrzChangeCalifornianQR.vue'
	import ChangeEnvironment from './FrzChangeEnvironmentQR.vue'
    
    export default{
        components: {ChangeTunnel, ChangeChamber, ChangeCalifornian, ChangeEnvironment},
        props: {},
        data: () => ({
            currentItem: "tab-Funciones"
    }),    
};
</script>
