<template>
	<v-card>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title>
							<v-row style="margin-top: 10px">
								<v-col cols="12" style="display: inline-flex;">
									<v-chip
										style="margin: 0px"
										small
										:color="'info'"
									>
										{{ "Ingreso QR" }} </v-chip
									>
								</v-col>
							</v-row>
						</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-row justify="center" style="margin:auto" v-if="objEnvironment !== null">
							<v-col
								align="center"
								cols="12"
								lg="12"
								md="12"
							>
								<h2><b>{{objEnvironment.MamName}} - </b>
									
									<v-chip
										large
										class="ma-2"
										variant="outlined"
										:color="objEnvironment.inEnvironmentStateColor"
										>{{
											objEnvironment.inParihuelaUsage
										}}
									</v-chip>
									<v-chip
										large
										:color="objEnvironment.inEnvironmentStateColor"
										><b>{{
											objEnvironment.inEnvironmentStateName
										}}</b>
									</v-chip>

								</h2>
							</v-col>
						</v-row>
						<v-row justify="center" style="margin:auto" v-if="objEnvironment !== null">
							<v-col
								align="center"
								cols="12"
								lg="12"
								md="12"
							>
								<s-scanner-qr
									:config="configScann"
									return-object
									:autofocus="false"
									@onValue="onValue($event)"
									:readonly="false"

								></s-scanner-qr>
							</v-col>
						</v-row>
						

						<v-row style="margin:auto" justify="center">

							<v-col cols="12">
								<s-select
									:items="itemsEnvironment"
									item-value="MamID"
									item-text="MamName"
									label="Seleccionar Plataforma"
									return-object
									autocomplete
									v-model="objEnvironment"
									:slotTmp="true"
								>
									<template v-slot:item="data">
										{{ data.item.MamName }}
										
										<v-chip
											small
											class="ma-2"
											variant="outlined"
											:color="data.item.inEnvironmentStateColor"
											><b>{{
												data.item.inParihuelaUsage
											}}</b>
										</v-chip>
										<v-chip
											small
											:color="data.item.inEnvironmentStateColor"
											><b>{{
												data.item.inEnvironmentStateName
											}}</b>
										</v-chip>

										

										
										
									</template>
								</s-select>
							</v-col>

						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>
		<v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessing.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
	</v-card>
</template>

<script>
	import sTunnelMaduration from "../../../../services/FrozenProduction/TunnelMadurationService";
	import _sMaturationTunnelPallet from "../../../../services/FrozenProduction/MaturationTunnelPalletService";
	import signalr from "signalr";
	export default {
		components: {},
		data() {
			return {
				
				configScann: {},
				item: {},
				itemsEnvironment: [],
				objEnvironment: null,
				processing:false,
				messageProcessing: "",
				StatusChange: true
			};
		},

		watch: {
		},

		created() {
			this.initialize()
		},

		methods: {

			initialize(){
				sTunnelMaduration.listEnviroment({}, this.$fun.getUserID()).then(r => {
					if(r.status == 200){

						r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.MamCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.MamCapacity)- parseInt(element.inParihuelaUsage)
							
							
							//Capacidad llena
							if(element.ParihuelaUsagePorcentaje == 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 50 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 50){
								element.inColor = 'success'
							}
	
						});

						this.itemsEnvironment = r.data;						

					}
					
				});
			},

			onValue(val) {
				this.verificateExist(val);
			},

			verificateExist(val)
			{
				let obj = {
					Origin: 5,
					LlpID: val
				} 
				_sMaturationTunnelPallet
				.verificarentry(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.countExist = resp.data;
						
						if(resp.data >= 1)
						{
							// this.$fun.alert("¿Desea mover el pallet?", "question")
							// .then(r =>{
							// 	if(r.value)
							// 	{
							if(this.countExist >= this.objEnvironment.MtsID)
							{
								this.StatusChange = false;									
							}

							if(this.StatusChange)
							{
								this.save(val)
							}
							else
							{
								this.$fun.alert("¿Esta intentando mover un pallet de un ambiente de estado mayor a uno menor?", "question")						
								.then(r =>{
									if(r.value)
									{	
										this.save(val)
									}
								})
							}
							this.clearInput = true;
							// 	}
							// })
						}
						else{
                            this.$fun.alert("Error, el pallet no se encuentra en ninguno de los ambientes. Por favor verifique", "warning")
                            return;
					    }
					}
				})
			},

			save(LlpID) {
				let item = {}
				if(this.objEnvironment.ParihuelaAvailableUnit == 0){
					this.$fun.alert("Error, Ambiente se encuentra lleno. Por favor verifique", "warning")
					return;
				}
				
				item.LlpID  = parseInt(LlpID)
				item.MamID = this.objEnvironment.MamID;
				item.SecStatus = 1
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
				

                this.processing = true;
                _sMaturationTunnelPallet
                    .saveChangeEnvironment(item, this.$fun.getUserID())
                    .then(resp => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.$fun.alert("Parihuela ingresada correctamente", "success");
                            this.initialize()
                        }
                    },
                    (e) => {
                        this.processing = false;
                    });
				
			},

		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.FrozenEntryParihuelaIntunnel = group => {
					this.initialize()
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingEntryParihuelaIntunnel");
				});
			});
		},
	};
</script>