<template>
	<v-card>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title>
							<v-row style="margin-top: 10px">
								<v-col cols="12" style="display: inline-flex;">
									<v-chip
										style="margin: 0px"
										small
										:color="'info'"
									>
										{{ "Ingreso QR" }} </v-chip
									>
								</v-col>
							</v-row>
						</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-row justify="center" style="margin:auto" v-if="objTunnel !== null">
							<v-col
								align="center"
								cols="12"
								lg="12"
								md="12"
							>
								<h1><b>{{objTunnel.FtmDescription}} - </b>
									<v-chip
										large
										class="ma-2"
										variant="outlined"
										:color="objTunnel.inTunnelStateColor"
										><b>{{
											objTunnel.inParihuelaUsage
										}}</b>
									</v-chip>
									<v-chip
										 large
										:color="objTunnel.inTunnelStateColor"
										><b>{{
											objTunnel.inTunnelStateName
										}}</b>
									</v-chip>
									
								</h1>
							</v-col>
						</v-row>
						
						<v-row justify="center" style="margin:auto" v-if="objTunnel !== null">
							<v-col
								align="center"
								cols="12"
								lg="12"
								md="12"
							>
								<s-scanner-qr
									:config="configScann"
									
									:autofocus="false"
									@onValue="onValue($event)"
									:readonly="false"
									
								></s-scanner-qr>
							</v-col>
						</v-row>
						<v-row justify="center"  v-if="objTunnel !== null">
							<v-col cols="3" v-if="objTunnel.inParihuelaUsage == 0">
								<s-select
									:items="itemsStateTunnel"
									item-value="MtsID"
									item-text="MtsDescription"
									label="Estado"
									v-model="TypeStateMp"
								>
								</s-select>
								
							</v-col>
						</v-row>
 
						<v-row style="margin:auto" justify="center">

							<v-col cols="12">
								<s-select
									:items="itemsTunnel"
									item-value="FtmID"
									item-text="FtmDescription"
									label="Seleccionar Tunnel"
									return-object
									
									autocomplete
									v-model="objTunnel"
									:slotTmp="true"
								>
									<template v-slot:item="data">
										{{ data.item.FtmDescription }}
																			
										<v-chip
											small
											class="ma-2"
											variant="outlined"
											:color="data.item.inTunnelStateColor"
											><b>{{
												data.item.inParihuelaUsage
											}}</b>
										</v-chip>

										<v-chip
											small
											:color="data.item.inTunnelStateColor"
											><b>{{
												data.item.inTunnelStateName
											}}</b>
										</v-chip>
									</template>
								</s-select>
							</v-col>

						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

			<v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessing.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
	</v-card>
</template>

<script>

	import sTunnelMaduration from "../../../../services/FrozenProduction/TunnelMadurationService";
	import _sMaturationTunnelPallet from "../../../../services/FrozenProduction/MaturationTunnelPalletService";
	import signalr from "signalr";

	export default {
		components: {},
		data() {
			return {
				clearInput:false,
				configScann: {},
				item: {},
				itemsTunnel: [],
				objTunnel: null,

				processing:false,
				messageProcessing: "",
				EstateActualPallet : 0,
				TypeStateMp: 0,
				itemsStateTunnel: [],
				StatusChange: true
			};
		},

		watch: {
		},

		created() {
			this.initialize()
		},

    	methods: {

        	initialize(){
            	sTunnelMaduration.list({}, this.$fun.getUserID()).then(r => {
                	if(r.status == 200){

                    	r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.FtmCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.FtmCapacity)- parseInt(element.inParihuelaUsage)
							
							
							//Capacidad llena
							if(element.ParihuelaUsagePorcentaje == 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 50 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 50){
								element.inColor = 'success'
							}

						});

                    	this.itemsTunnel = r.data;
                    
                    	if(this.objTunnel != null){
                        	this.itemsTunnel.forEach(value=>{							
								if(value.FtmID == this.objTunnel.FtmID)
								{
									this.objTunnel = value;
								}
                        });							
                    }
                }
                
            });

			_sMaturationTunnelPallet.listStateMaturation(this.$fun.getUserID()).then(r => {
				if(r.status == 200){
					this.itemsStateTunnel = r.data
					this.itemsStateTunnel.splice(3,1);
				}
			})
		},

        onValue(val) {
			this.verificateExist(val);
		},

		verificateExist(val)
		{
			this.StatusChange = true;	
			let obj = {
				Origin: 5,
				LlpID: val
			} 
			_sMaturationTunnelPallet
			.verificarentry(obj, this.$fun.getUserID())
			.then(resp => {
				if(resp.status == 200)
				{
					this.EstateActualPallet = resp.data;
					
					if(resp.data >= 1)
					{
						// this.$fun.alert("¿Desea mover el pallet?", "question")						
						// .then(r =>{
						// 	if(r.value)
						// 	{		
									
								if(this.EstateActualPallet > this.objTunnel.MtsID && this.objTunnel.MtsID <= 4)
								{
									this.StatusChange = false;																					
								}

								if(this.EstateActualPallet > this.TypeStateMp && this.TypeStateMp != 0)
								{									
									this.StatusChange = false;									
								}							
								
								
								if(this.StatusChange)
								{
									this.save(val)
								}
								else
								{
									this.$fun.alert("¿Esta intentando mover un pallet de un ambiente de estado mayor a uno menor, Deseas Continuar ?", "question")						
									.then(r =>{
										if(r.value)
										{	
											this.save(val)
										}
									})
								}
						// 	}
						// })
					}
					
					else{
						this.$fun.alert("Error, el pallet no se encuentra en ninguno de los ambientes. Por favor verifique", "warning")
                		return;
					}
				}
			})

		},

        save(LlpID) {
            let item = {}
            if(this.objTunnel.ParihuelaAvailableUnit == 0){
                this.$fun.alert("Error, tunnel se encuentra lleno. Por favor verifique", "warning")
                return;
            }
            
            item.LlpID  = parseInt(LlpID)
            item.FtmID = this.objTunnel.FtmID;
            item.SecStatus = 1
            item.UsrCreateID = this.$fun.getUserID();
            item.UsrUpdateID = this.$fun.getUserID();
            item.TypeStateMp = this.TypeStateMp; //samir

            if(this.objTunnel.inParihuelaUsage == 0){
                if (this.TypeStateMp == null ||  this.TypeStateMp == 0) {
                    this.$fun.alert("Por Favor Ingrese el Estado ", "warning");
                    return;
                }
            }

            this.processing = true;
                _sMaturationTunnelPallet
                    .saveChangeTunnel(item, this.$fun.getUserID())
                    .then(resp => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.$fun.alert("Parihuela se movio correctamente", "success");
							this.TypeStateMp = 0;
                            this.initialize();
                        }
                    },
                    (e) => {
                        this.processing = false;
                    }
                );            
            },
        },

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.FrozenEntryParihuelaIntunnel = group => {
					this.initialize()
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingEntryParihuelaIntunnel");
				});
			});
		},
    };
</script>
