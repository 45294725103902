<template>
	<v-card>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title>
							<v-row style="margin-top: 10px">
								<v-col cols="12" style="display: inline-flex;">
									<v-chip
										style="margin: 0px"
										small
										:color="'info'"
									>
										{{ "Ingreso QR" }} </v-chip
									>
								</v-col>
							</v-row>
						</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-row justify="center" style="margin:auto" v-if="objChamber !== null">
							<v-col
								align="center"
								cols="12"
								lg="12"
								md="12"
							>
								<h2><b>{{objChamber.CchName}} - </b>
										
										<v-chip
											large
											class="ma-2"
											variant="outlined"
											:color="objChamber.inTunnelStateColor"
											><b>{{
												objChamber.inParihuelaUsage
											}}</b>
										</v-chip>
										<v-chip
											large
											:color="objChamber.inTunnelStateColor"
											><b>{{
												objChamber.inTunnelStateName
											}}</b>
										</v-chip>
								</h2>
							</v-col>
						</v-row>
						<v-row justify="center" style="margin:auto" v-if="objChamber !== null">
							<v-col
								align="center"
								cols="12"
								lg="12"
								md="12"
							>
								<s-scanner-qr
									:config="configScann"
									return-object
									:autofocus="false"
									@onValue="onValue($event)"
									:readonly="false"

								></s-scanner-qr>
							</v-col>
						</v-row>	
						<v-row style="margin:auto" justify="center">

							<v-col cols="12">
								<s-select
									:items="itemsChamber"
									item-value="CchID"
									item-text="CchName"
									label="Seleccionar Cámara"
									return-object
									
									autocomplete
									v-model="objChamber"
									:slotTmp="true"
								>
									<template v-slot:item="data">
										{{ data.item.CchName }}
										
										<v-chip
											small
											class="ma-2"
											variant="outlined"
											:color="data.item.inTunnelStateColor"
											><b>{{
												data.item.inParihuelaUsage
											}}</b>
										</v-chip>
										<v-chip
											small
											:color="data.item.inTunnelStateColor"
											><b>{{
												data.item.inTunnelStateName
											}}</b>
										</v-chip>

										

										
										
									</template>
								</s-select>
							</v-col>

						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

			<v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessing.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
	</v-card>
</template>

<script>
    import sTunnelMaduration from "../../../../services/FrozenProduction/TunnelMadurationService";
	import _sMaturationTunnelPallet from "../../../../services/FrozenProduction/MaturationTunnelPalletService";

    export default{
        components: {},
        data() {
            return {
                configScann: {},
				item: {},
				itemsChamber: [],
				objChamber: null,
				processing:false,
				messageProcessing: "",
				StatusChange: true
            };
        },

        created() {
			this.initialize()
		},

		methods: {

			initialize(){
				sTunnelMaduration.listChamber({}, this.$fun.getUserID()).then(r => {
					if(r.status == 200){

						

						r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.CchCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.CchCapacity)- parseInt(element.inParihuelaUsage)
								
						});

						this.itemsChamber = r.data;					

					}
					
				});
			},

			onValue(val) {
				this.verificateExist(val);
			},

			verificateExist(val)
			{
				this.StatusChange = true;	
				let obj = {
					Origin: 5,
					LlpID: val
				} 
				_sMaturationTunnelPallet
				.verificarentry(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.countExist = resp.data;
						
						if(resp.data >= 1)
						{							
							if(this.countExist > this.objChamber.MtsID && this.objChamber.MtsID <= 4)
							{
								this.StatusChange = false;																					
							}

							if(this.StatusChange)
							{
								this.save(val)
							}
							else
							{
								this.$fun.alert("¿Esta intentando mover un pallet de un ambiente de estado mayor a uno menor?", "question")						
								.then(r =>{
									if(r.value)
									{	
										this.save(val)
									}
								})
							}								
						}
						else{
							this.$fun.alert("Error, el pallet no se encuentra en ninguno de los ambientes. Por favor verifique", "warning")
                			return;
					    }
					}
				})
			},

			save(LlpID) {
				let item = {}
				
				item.LlpID  = parseInt(LlpID)
				item.CchID = this.objChamber.CchID;
				item.SecStatus = 1
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();

				
                this.processing = true;
                _sMaturationTunnelPallet
                    .saveChangeChamber(item, this.$fun.getUserID())
                    .then(resp => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.$fun.alert("Parihuela ingresada correctamente", "success");
                            this.initialize()
                        }
                    },
                    (e) => {
                        this.processing = false;
                    });
				
			},

		},
    }
</script>